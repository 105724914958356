import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import { SeoType } from '@/types.d'
import { Gutenberg } from '@/components/Gutenberg'
import RelatedItem from '@/components/RelatedItem'
import GravityForm from '@/components/GravityForm'

interface DefaultData {
  data: {
    form: any
    page: {
      title: string
      content: string
      uri: string
      seo: SeoType
      image: any
      blocks: any
      blocksJSON: any
      bgelement: any
      bgprosent: number
      showposts: boolean
      heroFilter: boolean
    }
    posts: any
    menu?: any
    quickmenu?: any
  }
  pageContext: {
    postlang: string
  }
}

const DefaultTemplate = ({ data }: DefaultData) => {
  // Page data
  const { page, posts, form } = data
  // Menus
  const { menu, quickmenu } = data

  const heroContent = {
    image: page?.image,
    disableBackButton: true,
    filter: page?.heroFilter,
  }

  return (
    <Layout
      title={page?.title}
      hero={heroContent}
      nav={menu}
      quickNav={quickmenu}
      uri={page?.uri}
      seo={page?.seo}
      useBreadCrump={true}
      bgimage={page?.bgelement?.value}
      bgprosent={page?.bgprosent}
    >
      <div className="mx-auto max-w-[1100px]">
        <Gutenberg blocks={page?.blocks} />
      </div>
      {posts && page?.showposts && (
        <div className="relateditems grid md:grid-cols-3 gap-6 mt-20">
          {posts?.nodes?.map((post: any) => {
            return (
              <RelatedItem
                key={post?.id}
                heading={post?.title}
                content={post?.content}
                to={post?.uri}
                date={post?.date}
                featuredType={post?.terms?.nodes}
              />
            )
          })}
        </div>
      )}
      {form && (
        <div className="mx-auto mt-10 max-w-[1100px]"> 
          <GravityForm form={form} />
        </div>
      )}
    </Layout>
  )
}

export const PageQuery = graphql`
  query PageById($id: String, $pagetag: [String], $formID: Int) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      uri
      heroFilter: gwShowPostHeaderFilter
      showposts: gwShowPostsOnPage
      ...bg
      ...pageBlocks
      ...seo
      ...featuredHeroImage
    }
    menu: wpMenu(locations: { eq: MAIN_MENU }) {
      ...navigation
    }
    posts: allWpPost(
      limit: 3
      sort: { order: DESC, fields: date }
      filter: { tags: { nodes: { elemMatch: { name: { in: $pagetag } } } } }
    ) {
      ...allPostsExtended
    }
    form: wpGravityFormsForm(formId: { eq: $formID }) {
      ...GravityFormFields
    }
    quickmenu: wpMenu(locations: { eq: QUICK_NAV }) {
      ...navigation
    }
  }
`

export default DefaultTemplate
